import { Button, ButtonSize } from '@amzn/stencil-react-components/esm/button';
import { Col, Row } from '@amzn/stencil-react-components/esm/layout';
import { IconBin } from '@amzn/stencil-react-components/esm/submodules/icons/icons';
import { RowData, Table, TableColumn } from '@amzn/stencil-react-components/esm/table';
import { H2, P } from '@amzn/stencil-react-components/esm/text';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCredentialManagementStore } from '../../../context/credentialManagementContext';
import { WebAuthNCredential } from '../../../globals/types';
import DeleteCredentialModal from './DeleteCredentialModal';
import { LOGIN_FORM_STATES, useLoginStore } from '../../../context/loginContext';
import NameCredentialModal from './../CustomLoginUI/NameCredentialModal';
import { registerCredential } from '../../../globals/utils';
import ExclamationCircle from '../../../assets/exclamation-circle-small.svg';

export default function CredentialManagement() {
    const { t } = useTranslation();

    const getListCredentials = useCredentialManagementStore((state) => state.getListCredentials);
    const credentials = useCredentialManagementStore((state) => state.data);
    const deleteSuccess = useCredentialManagementStore((state) => state.deleteSuccess);
    const deleteError = useCredentialManagementStore((state) => state.deleteError);
    const isGettingCredentials = useCredentialManagementStore(state => state.loading);
    const setLoginFormState = useLoginStore((state) => state.setLoginFormState);
    const setShowSpinnerOverlay = useLoginStore((state) => state.setShowSpinnerOverlay);
    const [credentialToDelete, setCredentialToDelete] = useState<WebAuthNCredential | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNameCredentialModal, setShowNameCredentialModal] = useState(false);
    const [credentialName, setCredentialName] = useState('')
    const [showWebAuthNNotSupportedError, setShowWebAuthnNotSupportedError] = useState(false);
    const setPromptRegisterWebauthn = useLoginStore((state) => state.setPromptRegisterWebauthn);
    const [showErrorCollectingWebAuthNKey, setShowErrorCollectingWebAuthNKey] = useState(false);
    const cognitoUser = useLoginStore((state) => state.cognitoUser);
    const onClickAddCredential = async () => {
        setShowNameCredentialModal(true);
    }


    useEffect(() => {
        getListCredentials()
    }, [deleteSuccess, deleteError]);

    useEffect(() => {
        setShowSpinnerOverlay(isGettingCredentials);
    }, [isGettingCredentials]);

    useEffect(() => {
        const startRegisteringCredentials = async () => {
            if (!showNameCredentialModal && credentialName.length) {
                if (!window.PublicKeyCredential) { // Client not capable of webauthn
                    setShowWebAuthnNotSupportedError(true);
                }
                // call register apis
                if (cognitoUser) {
                    try {
                        setShowSpinnerOverlay(true);
                        const session = await Auth.currentSession();
                        await registerCredential(cognitoUser.getUsername(), session.getIdToken().getJwtToken(), credentialName);
                        setPromptRegisterWebauthn(false);
                        setShowSpinnerOverlay(false);
                        setCredentialName('')
                        getListCredentials();
                    } catch (err) {
                        setShowErrorCollectingWebAuthNKey(true);
                        setShowSpinnerOverlay(false);
                        setCredentialName('')
                    }
                }
            }
        }
        startRegisteringCredentials();
    }, [showNameCredentialModal]);

    const columns: TableColumn[] = [
        { header: 'Registration Time', accessor: ({ data }: RowData) => (data as WebAuthNCredential).registrationDate },
        { header: 'Name', accessor: ({ data }: RowData) => { return (data as WebAuthNCredential).name ?? data.id.substring(0, 5)} },
        {
            cellComponent: ({ data }: RowData) => {
                return <Button
                    data-testid='delete-credential-button'
                    icon={<IconBin aria-hidden={true} />}
                    isDestructive={true}
                    onClick={() => { setCredentialToDelete(data); setShowDeleteModal(true) }}
                    size={ButtonSize.Small}
                />
            },
            header: '',
        },
    ];

    return (
        <>
            <NameCredentialModal credentialName={credentialName} setCredentialName={setCredentialName} showModal={showNameCredentialModal} setShowModal={setShowNameCredentialModal} />
            <Col gridGap='S200'>
                <H2 id="table-label" data-testid='table-header' fontSize="T400">
                    {t('resources:credentialsHeader')}
                </H2>
                <Table data-testid='credential-table' aria-labelledby="table-label" columns={columns} data={credentials ?? []} />
                <DeleteCredentialModal credential={credentialToDelete} showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} />
                {showErrorCollectingWebAuthNKey && (
                    <Row
                        gridGap='S200'
                        justifyContent='center'
                        alignItems='center' hidden={showErrorCollectingWebAuthNKey}>
                        <img width='auto' src={ExclamationCircle} alt='ExclamationCircle' />
                        <P fontSize="T200">{t('resources:errorCapturingWebauthn')}</P>
                    </Row>)}
                {showWebAuthNNotSupportedError && (
                    <Row
                        gridGap='S200'
                        justifyContent='center'
                        alignItems='center' hidden={showWebAuthNNotSupportedError}>
                        <img width='auto' src={ExclamationCircle} alt='ExclamationCircle' />
                        <P fontSize="T200">{t('resources:webauthnNotSupported')}</P>
                    </Row>)}
                <Button
                    data-testid="add-credential-button"
                    onClick={() => onClickAddCredential()}
                >
                    {t('resources:addCredential')}
                </Button>
                <Button
                    onClick={() =>
                        setLoginFormState(LOGIN_FORM_STATES.LOGGED_IN_STATE)
                    }
                    data-testid="back-button"
                    style={{ width: '100%' }}
                >
                    {t('resources:back')}
                </Button>
            </Col>
        </>
    );
}
